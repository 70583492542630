import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import React, { Component } from 'react';
import { Dropdown, Button } from "monday-ui-react-core";
import QuantitySelector from '../QuantitySelector/QuantitySelector';


class Table extends Component {
    constructor(props) {
        super(props);

        this.state = {
            products: this.props.products,
            poItems: this.props.items,
            filteredProducts: this.props.products,
            showFilter: false,
            stockBoardColumns: [],
            stockBoardValues: [],
        }

        this.onShowFilterChange = this.onShowFilterChange.bind(this);
    }

    componentDidMount() {
        
    }

    onShowFilterChange() {
        this.setState({showFilter: !this.state.showFilter});
    }

    editProduct(index, value) {
        this.props.editProduct(index, value);
    }

    render() {
        const columns = [
            {
                Header: 'Product',
                accessor: 'product', // accessor is the "key" in the data
                Cell: (cell) => {
                    return (
                        <>
                            <Dropdown 
                                className="dd-option"
                                value={this.props.items[cell.index].product} 
                                options={this.props.products} 
                                noOptionsMessage={() => {return "No products found"}} 
                                onChange={(value) => this.editProduct(cell.index, value)} 
                            />
                            <br />
                            <br />
                            <br />
                        </>

                    )
                }

            },
            {
                Header: 'Quantity',
                accessor: 'quantity',
                Cell: (cell) => {
                    return <QuantitySelector min={1} value={this.props.items[cell.index].quantity} onChange={ (value) => this.props.editQuantity(cell.index, value) } />
                }
            },
            {
                Header: '',
                accessor: '',
                Cell: (cell) => {
                    return <Button marginLeft marginRight onClick={() => this.props.removeItem(cell.index)} > Remove Item </Button>
                }
            }
         ]
        return ( 
            <>
                {/* <Button onClick={this.onShowFilterChange} className="mb-3">Filter Products</Button>
                <ProductsFilterModal 
                    showModal={this.state.showFilter} 
                    columns={this.state.stockBoardColumns} 
                    values={this.state.stockBoardValues} 
                /> */}
                <ReactTable
                    showPagination={false}
                    data={this.props.items}
                    minRows={3}
                    columns={columns}
                    className={"table table-bordered table-hover"}
                    noDataText={"Please add an item to the purchase order"}
                />
            </>
        );
    }
}

export default Table;

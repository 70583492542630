import React, { Component } from 'react';
import './welcome.scss'
import LogoImg from '../../public/images/luxietech-full.png';

class Welcome extends Component {


    render() {

        return (
            <div className="login-container text-center">
                <div className="logo-container">
                    <img src={LogoImg} alt={"Luxie Tech Logo"} />
                </div>
                <div>
                    <h3> Welcome to monday.com Inventory by LuxieTech</h3>
                </div>
            </div>
        );
    }
}

export default Welcome
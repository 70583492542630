import React, { useState, useEffect } from 'react';
import { useParams } from "react-router";
import Swal from 'sweetalert2';
import { request } from '../../constants/constants';
import { Dropdown, Button, Loader, ButtonGroup, TextField} from "monday-ui-react-core";
import ItemsTable from '../../Components/ItemsTable/ItemsTable'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import "bootstrap/dist/css/bootstrap.min.css";

function UsageExternal() {

    const [state, setState] = useState({
        is_loading: true,
        invalid_form: false,
        projects: [],
        selectedProject: null,
        subcategories: [],
        selectedCategory: null,
        selectedDate: null,
        items: [],
        stock: [],
        changeType: 'decrement',
        notes: '',
    });

    let { id } = useParams();

    useEffect(() => {
        request('GET', '/external/usage/' + id).then(res => {
            const stock = res.data.stock;
            const projects = res.data.projects;
            const subcategories = res.data.subcategories ? res.data.subcategories.map(cat => { return { label: cat, value: cat } }) : [];
            setState(state => ({ ...state, is_loading: false, stock: stock, projects: projects, subcategories: subcategories }))
        }).catch(error => {
            console.error(error);
            setState(state => ({ ...state, invalid_form: true, is_loading: false }))
        })
    }, [id]);
    
    const submitUsage = (event) => {
        event.preventDefault();
        if (checkSubmissionDetails()) {
            let data = {
                project_id: state.selectedProject ? state.selectedProject.value : null,
                subcategory: state.selectedCategory ? state.selectedCategory.value : null,
                date: state.selectedDate,
                items: state.items,
                type: state.changeType,
                notes: state.notes,
            }
            setState({...state, is_loading: true});
            request('POST', '/external/usage/' + id, data).then(res => {
                Swal.fire({ title: "Success", text: res.data.message, type: "success", timer: 5000 });
                setState({ ...state, selectedCategory: null, selectedProject: null, items: [], is_loading: false});
            }).catch(err => {
                Swal.fire({ title: "Error", text: err.response.data.message, type: "error", timer: 5000 });
                setState({ ...state, is_loading: false });
            });
        } else {
            Swal.fire({ title: "Error", text: "Please complete all required fields", type: "warning", timer: 5000 });
        }
    }
    
    const checkSubmissionDetails = () => {
        if (state.items.length === 0) {
            return false;
        }

        if (state.selectedProject === null) {
            return false;
        }

        if (state.selectedDate === null) {
            return false;
        }

        for (var i = 0; i < state.items.length; i++) {
            let item = state.items[i];
            if (item.product === null || item.quantity === null) {
                return false;
            }
        }

        return true;
    }


    const handleQuantityChange = (index, value) => {
        let currentItems = state.items;
        currentItems[index].quantity = value;
        setState({...state, items: currentItems });
    }

    const handleProjectChange = ( value ) => {
        setState({...state, selectedProject: value});
    }

    const handleSubcategoryChange = (value) => {
        setState({ ...state, selectedCategory: value });
    }

    const handleDateChange = (date) => {
        setState({ ...state, selectedDate: date.toISOString().split('T')[0]});
    }

    const handleNotesChange = (value) => {
        setState({ ...state, notes: value});
    }

    const addNewItem = () => {
        let currentItems = state.items;
        currentItems.push({ product: null, quantity: 1, location: null });
        setState({...state, items: currentItems });
    }

    const itemProductChange = (index, value) => {
        let currentItems = state.items;
        currentItems[index].product = value;
        setState({...state, items: currentItems });
    }

    const removeItem = (index) => {
        let currentItems = state.items;
        currentItems.splice(index, 1);
        setState({...state, items: currentItems });
    }

    const handleTypeChange = (value) => {
        setState({ ...state, changeType: value });
    }

    if (state.is_loading) {
        return (
            <div className="container">
                <div class="row h-100">
                    <div class="col-sm-12 my-auto">
                        <div className='mx-auto' style={{ height: '50px', width: '200px', paddingTop: '250px' }}>
                            <div className='d-flex justify-content-center' style={{
                                color: "var(--primary-color)"
                            }}>
                                <Loader size={50} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else if (state.invalid_form) {
        return (
            <div className="container">
                <div class="row h-100">
                    <div class="col-sm-12 my-auto">
                        <div className='mx-auto' style={{ height: '50px', width: '200px', paddingTop: '250px' }}>
                            <p>Invalid Form</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {

        return (
            
            <div className="container">
                <div>
                    <h3 className="text-center mt-4">Submit Usage</h3>
                    <div className='row mt-2'>
                        <div className='col-6 mx-auto'>
                            <ButtonGroup
                                onSelect={handleTypeChange}
                                options={[{ 'text': 'Add', 'value': 'increment' }, { 'text': 'Subtract', 'value': 'decrement' }]}
                                value={state.changeType}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-6 mb-2 mx-auto">
                            <span>Project</span>
                            <Dropdown
                                title={"Project:"}
                                options={state.projects}
                                noOptionsMessage={() => { return "No projects found" }}
                                onChange={handleProjectChange}
                                value={state.selectedProject}
                            />
                        </div>
                    </div>
                    <br />
                    {state.subcategories.length > 0 &&
                        <div className="row mt-2">
                            <div className="col-6 mb-2 mx-auto">
                                <span>Subcategory</span>
                                <Dropdown
                                    title={"Subcategory:"}
                                    options={state.subcategories}
                                    noOptionsMessage={() => { return "No subcategories found" }}
                                    onChange={handleSubcategoryChange}
                                    value={state.selectedCategory}
                                />
                            </div>
                        </div>
                    }
                    <br />
                    <div className="row">
                        <div className="col-6 mb-2 mx-auto">
                            <span>Usage Date</span>
                            <DatePicker inline onChange={handleDateChange} />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-6 mb-2 mx-auto">
                            <TextField title="Notes" placeholder="Notes" size={TextField.sizes.LARGE} onChange={handleNotesChange} />
                        </div>
                    </div>
                    <br />

                    <ItemsTable
                        items={state.items}
                        products={state.stock}
                        removeItem={removeItem}
                        editProduct={itemProductChange}
                        editQuantity={handleQuantityChange}
                        stockBoardId={state.stockBoardId}
                    />
                    <br />
                    <Button marginLeft marginRight onClick={addNewItem} > Add Item </Button>
                    <Button marginLeft marginRight onClick={submitUsage} > Submit Usage </Button>
                </div>
            </div>
        )
    }
}

export default UsageExternal
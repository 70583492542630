import React, { useState, useEffect } from 'react';
import { useParams } from "react-router";
import Swal from 'sweetalert2';
import { request } from '../../constants/constants';
import { Dropdown, Button, Heading, Loader, ButtonGroup, TextField } from "monday-ui-react-core";
import { Send } from "monday-ui-react-core/dist/allIcons";

function UsageItem() {

    const [state, setState] = useState({
        item_name: '',
        is_loading: true,
        invalid_item: false,
        selected_quantity: 0,
        selected_type: "reduce",
        projects: [],
        selectedProject: null,
        notes: '',
        categories: [],
        selectedCategory: null,
        changeType: 'decrement',
        coil: false,
    });

    let { id } = useParams();

    useEffect(() => {
        request('GET', '/monday-stock-item/' + id).then(res => {
            const item = res.data.item_name;
            const projects = res.data.project_items;
            const subcategories = res.data.subcategories;
            const coil = res.data.coil;
            setState(state => ({ ...state, item_name: item, is_loading: false, projects: projects, categories: subcategories, coil: coil }))
        }).catch(error => {
            setState(state => ({ ...state, invalid_item: true, is_loading: false }))
        })
    }, [id]);
    
    const handleFormSubmit = (event) => {
        event.preventDefault();
        let data = {
            quantity: state.selected_quantity,
            item_id: id,
            type: state.changeType,
            project_id: state.selectedProject ? state.selectedProject.value : null,
            notes: state.notes,
            subcategory: state.selectedCategory ? state.selectedCategory.value : null,
        }
        setState({...state, is_loading: true});
        request('POST', '/monday-stock-item/update-value', data).then(res => {
            Swal.fire({ title: "Success", message: res.data.message, type: "success", timer: 5000 });
            setState({ ...state, selected_quantity: 0, is_loading: false });
        }).catch(err => {
            Swal.fire({ title: "Error", text: err.response.data.message, type: "error", timer: 5000 });
            setState({ ...state, is_loading: false });
        });
    }

    const handleQuantityChange = (event) => {
        setState({ ...state, selected_quantity: event.target.value })
    }

    const handleProjectChange = ( value ) => {
        setState({...state, selectedProject: value});
    }

    const handleNotesChange = (value) => {
        setState({ ...state, notes: value });
    }

    const handleCategoryChange = (value) => {
        setState({ ...state, selectedCategory: value });
    }

    const handleTypeChange = (value) => {
        setState({ ...state, changeType: value });
    }

    if (state.is_loading) {
        return (
            <div className="container">
                <div class="row h-100">
                    <div class="col-sm-12 my-auto">
                        <div className='mx-auto' style={{height:'50px', width:'200px', paddingTop:'250px'}}>
                            <div className='d-flex justify-content-center' style={{
                                color: "var(--primary-color)"
                            }}>
                                <Loader size={50} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else if (state.invalid_item) {
        return (
            <div className="container">
                <div class="row h-100">
                    <div class="col-sm-12 my-auto">
                        <div className='mx-auto' style={{height:'50px', width:'200px', paddingTop:'250px'}}>
                            <p>Invalid Item</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className='container mt-5'>
                <div className='row d-flex justify-content-center'>
                    <div className='card ' style={{ width: '30rem' }}>
                        <div className="card-body ">
                            <div className='card-title d-flex justify-content-center'>
                                <Heading type={Heading.types.h1} value={state.item_name} id="my-work-id" />
                            </div>
                            <form onSubmit={handleFormSubmit}>

                                <div className="form-group">
                                    <div className='row mt-2'>
                                        <div className='col-6 mx-auto'>
                                            <ButtonGroup 
                                                onSelect={handleTypeChange}
                                                options={[{'text':'Add', 'value':'increment'}, {'text':'Subtract','value':'decrement'}]}
                                                value={state.changeType}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-6 mx-auto'>
                                            {state.changeType === 'increment' && 
                                                <label> Add to stock count by</label>
                                            }
                                            {state.changeType === 'decrement' &&
                                                <label> Take  from stock count by</label>
                                            }
                                            <input type="number" class="form-control w-60 " id="inputquantity" 
                                            name="quantity" value={state.selected_quantity} min="0" 
                                            required onChange={handleQuantityChange} />
                                            {state.coil && 
                                                <span>mm</span>
                                            }
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        {state.projects && 
                                            <div className='row mt-2'>
                                                <div className="col-6 mx-auto">
                                                    <p> Project
                                                        <Dropdown placeholder="Project" 
                                                            size={Dropdown.size.SMALL} 
                                                            className="dropdown-stories-styles_spacing" 
                                                            options={state.projects}
                                                            noOptionsMessage={() => { return "No projects found" }}
                                                            onChange={handleProjectChange}
                                                        />
                                                    </p>
                                                </div>
                                            </div>
                                        }    
                                        {state.categories &&
                                            <div className='row mt-2'>
                                                <div className="col-6 mx-auto">
                                                    <p> Subcategories
                                                        <Dropdown placeholder="Subcategories" 
                                                            size={Dropdown.size.SMALL} 
                                                            className="dropdown-stories-styles_spacing" 
                                                            options={state.categories}
                                                            noOptionsMessage={() => { return "No subcategories found" }}
                                                            onChange={handleCategoryChange}
                                                        />
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                        <div className="row">
                                            <div className="col-6 mb-2 mx-auto">
                                                <TextField title="Notes" placeholder="Notes" size={TextField.sizes.LARGE} onChange={handleNotesChange} />
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <Button type='submit' rightIcon={Send}>Submit</Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                   
                </div>
            </div>
            
        )
    }
}

export default UsageItem